import './bootstrap';

/**
 * External dependencies
 */
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Internal dependencies
 */
import Sentry, { initializeSentry } from '@/services/sentry/sentry';
import { DrawersContextProvider } from '@/common/contexts/use-drawers';
import { ModalProvider } from './common/blocks/modal-manager/modal-manager';
import loadFontAwesome from './common/helpers/load-fontawesome';
import { CmsStateProvider } from '@/hosts-marketplace/contexts/use-cms-state';
import { AsidesProvider } from './common/contexts/use-asides';
import { ASIDES } from './hosts-marketplace/constants/asides';

initializeSentry();
loadFontAwesome();

const queryClient = new QueryClient();

const rootElement = document.getElementById('app');
const root = createRoot(rootElement);

createInertiaApp({
    resolve: (name) => {
        if (typeof name === 'undefined') {
            return resolvePageComponent('./Pages/HostMarketplace/Homepage.jsx', import.meta.glob('./Pages/**/*.jsx'));
        }

        return resolvePageComponent(`./Pages/HostMarketplace/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
    },
    setup({ _, App, props }) {
        const user = props.initialPage.props.auth.user;
        if (user) {
            Sentry.setUser({ email: user.email, username: user.name });
        }

        root.render(
            // <StrictMode>
            <QueryClientProvider client={queryClient}>
                <DrawersContextProvider>
                    <ModalProvider>
                        <CmsStateProvider>
                            <AsidesProvider asides={ASIDES}>
                                <App {...props} />
                            </AsidesProvider>
                        </CmsStateProvider>
                    </ModalProvider>
                </DrawersContextProvider>
            </QueryClientProvider>
            // </StrictMode>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
